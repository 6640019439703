import './social.css'
import {AiFillInstagram} from 'react-icons/ai'
import {BsTelegram} from 'react-icons/bs'

function Social() {
  return (
    // <div  className=" h-screen bg-cover bg-center no-repeat bg-[url('./images/bgd1.jpg')]">
    <div   className='social'>
    <ul>
         <li>
               <a className='aTel'  href="https://t.me/win100_support"><BsTelegram  size="3.2em"/></a>
         </li> 
         <li>
               <a className='insta' href="https://www.instagram.com/win100fx/"> <AiFillInstagram size='3.8em' color='#cd486b'/></a>
         </li>
       
    </ul>
   
</div>

    // </div>
  )
}

export default Social
