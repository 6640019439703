import React, { useState } from "react";
import { Link } from "react-router-dom";
//import * as Icons from "react-icons/fa";
import "./Navbar.css";
import { navItems } from "./NavItems";
import Button from "./Button";
import Dropdown from "./Dropdown";
import logo from '../images/logo.jpg'
function Navbar() {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="navbar-logo">
       
         <img src={logo} height={100}  alt=''/>
        </Link>
        <ul className="list">
          {navItems.map((item) => {
            if (item.title === "Services") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link to={item.path}>{item.test}</Link>
                  {dropdown && <Dropdown />}
                </li>
                          
              );
            }
            return (
              <li key={item.id} className={item.cName}>
                <Link to={item.path}>{item.test}</Link>
              </li>
            );
          })}
        </ul>
        <Button /><h3 className="message">با دادن اطلاعات نادرست به بازدیدکنندگان،بدحسابی، اقدام به کلاهبرداری و بی حرمتی، سایت پشتیبانی ندارد و بزودی در دسترس نخواهد بود.                          ، ,   .  , ،"گروه نگارنده سایت" </h3>


      </nav>
      
    </>
  );
}

export default Navbar;
