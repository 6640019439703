export const navItems = [
  {
    id: 1,
    test: "خانه",
    title: "Home",
    path: "./",
    cName: "nav-item",
  },
  {
    id: 2,
    test: "خدمات",
    title: "Services",
    path: "./services",
    cName: "nav-item",
  },
  {
    id: 3,
    test: "ارز دیجیتال",
    title: "Products",
    path: "./products",
    cName: "nav-item",
  },
  {
    id: 4,
    test: "راه گفتگو",
    title: "Contact Us",
    path: "./contactus",
    cName: "nav-item",
  },
];

export const serviceDropdown = [
  {
    id: 1,
    test: "بازاریابی",
    title: "Marketing",
    path: "./marketing",
    cName: "submenu-item",
  },
  {
    id: 2,
    test: "مشاوره",
    title: "Consulting",
    path: "./consulting",
    cName: "submenu-item",
  },
  {
    id: 3,
    test: "طراحی", 
    title: "Design",
    path: "./design",
    cName: "submenu-item",
  },
  {
    id: 4,
    test: "همکاری",
    title: "Development",
    path: "./development",
    cName: "submenu-item",
  },
];
