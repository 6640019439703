import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

function Button() {
  return (
<>    <Link to="signup">
      <button className="btn">عضویت</button>
    </Link>
      <Link to="login">
      <button className="btn">ورود</button>
    </Link></>
  );
}

export default Button;
