import React from "react";

function Design() {
  return (
    <div>
      <h3 className="design">Design</h3>
    </div>
  );
}

export default Design;
