//import {Fragment} from 'react'
//import {withLocation} from 'react-router-dom'
//import '../components/layout/social.css'
//import {AiFillInstagram} from 'react-icons/ai'
//import {BsTelegram} from 'react-icons/bs'
//import Social from '../components/layout/Social'


function Contactus() {
  return (
    <div>
      <h3 className="contactus">Contactus</h3>
    </div>
  );
}

export default Contactus;

/*  const Contactus= withLocation= props=> {

  return (    // <div  className=" h-screen bg-cover bg-center no-repeat bg-[url('./images/bgd1.jpg')]">
/*     <>
    <Fragment>
    <div className="contactus"> 
    
    {props.children}
    </div>
    </Fragment>
            
       </>
    // </div> */
    
  





//export default withRouter(Contactus); */
