import React from "react";
/* function stockMarket(){
  <script src="http://www.navasan.tech/wp-navasan.php?usd&eur&gbp&aed_note&try&jpy&aud&nzd&cad&sgd&chf&pkr&azn&nok&sek&dkk&kwd&omr&rub&brl&thb&afn&inr&cny&myr&gel&usd_sherkat&usd_shakhs&aed&eur_hav&gbp_hav&hav_cad_cheque&aud_hav&myr_hav&cny_hav&try_hav&jpy_hav&btc&eth&xrp&bch&eos&bnb&usdt&pp&mex_usd_sell&mex_usd_buy&mex_eur_sell&mex_eur_buy&xau&usd_xau&18ayar&sekkeh&bahar&nim&rob&abshodeh&gerami&bub_sekkeh&bub_bahar&bub_nim&bub_rob&bub_18ayar&bub_gerami"></script>
  fetch("http://www.navasan.tech/wp-navasan.php?usd&eur&gbp&aed_note&try&jpy&aud&nzd&cad&sgd&chf&pkr&azn&nok&sek&dkk&kwd&omr&rub&brl&thb&afn&inr&cny&myr&gel&usd_sherkat&usd_shakhs&aed&eur_hav&gbp_hav&hav_cad_cheque&aud_hav&myr_hav&cny_hav&try_hav&jpy_hav&btc&eth&xrp&bch&eos&bnb&usdt&pp&mex_usd_sell&mex_usd_buy&mex_eur_sell&mex_eur_buy&xau&usd_xau&18ayar&sekkeh&bahar&nim&rob&abshodeh&gerami&bub_sekkeh&bub_bahar&bub_nim&bub_rob&bub_18ayar&bub_gerami")
  .then(data => console.log(data))
  .then(data=>function navasanret(data){document.getElementById('navasan_table_container').innerHTML=JSON.parse(data)})
 
} */
function Marketing() {
  // document.getElementById('spanPrices').innerHTML =stockMarket()
   return (
    <div>
      <h1 className="marketing">Marketing</h1>
      

    </div>
  );
}

export default Marketing;
