import React, {  useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
function Login() {
    const history=useNavigate();
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    async function submit(e){
        e.preventDefault();

        try{
            await axios.post("https://mbmawin100.ir/signup",{
                email,password
            })
            .then(res=>{
                if(res.data==="exist"){
                    alert("کاربر پیشتر نام نویسی کرده")
                }
                else if(res.data==="notexist"){
                    history("/home",{state:{id:email}})
                }
            })
            .catch(e=>{
               
                console.log(e);
            })
        }
        catch(e){
            console.log(e);

        }

    }
    return (
        <div className="login">
             <form action="POST">
                <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email"  />
                <input type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" />
                <button type="submit" onClick={submit} >نام نویسی</button>
            </form>
            <br />
             <Link className="loginLink" to="/"> به صفحه ورود</Link>
        </div>
    )
}

export default Login