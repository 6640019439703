//import React , { useState} from "react";
//import {Link} from 'react-router-dom'
//import { useLocation } from "react-router-dom";
import img1 from '../images/bg/describe2.jpg'
function Home() {
 //const location =useLocation()
 var sites = [ 'https://t.me/win100_support',   'https://instagram.com/win100fx/'];
function randomSite() {
  var i = parseInt(Math.random() * sites.length);
  return sites[i];
} 
/* const [message, Setmessage]= useState("به دلیل اطلاعات نادرست به بازدیدکنندگان،بدحسابی یا کلاهبرداری، سایت پشبیبانی ندارد و بزودی در دسترس نخواهد بود")
  function confirmMsg(e){
  
         if (e.target.value){
                Setmessage("")}} */
  
  return (<>
  {/*   <div>
          <lable className="lableAd">{message} </lable>
          <Link to="/" ><button className="confirm"  onClick={confirmMsg}> </button> </Link>
         </div> */}
    <div className="home">
      {/* <h8 className="hi"> {location.state "from Home"} </h8> */}
     <a href={randomSite()}><button className="buySubscribe" > اشتراک کانال سیگنال</button></a>
      <a className="titr" href="https://t.me/win100_support" ><img className="titr" src={img1} alt=""></img>  </a>
      <div className="titr"></div>
      
     </div>
     
     </>
  );
}

export default Home;
