import React from "react";

function Consulting() {
  return (
    <div>
      <h3 className="consulting">Consulting</h3>
    </div>
  );
}

export default Consulting;
