import React from "react";

function Development() {
  return (
    <div>
      <h3 className="development">Development</h3>
    </div>
  );
}

export default Development;
